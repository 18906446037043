body {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding: 0;
  margin: 0;
}

.nav-item, .navbar-brand {
  display: inline-block;
}

.navbar {
  background-color: lightgray;
  padding: 0;
  margin: 0;
  border-bottom: solid black 1px;
  width: 100%;
}

.navbar-brand {
  background-color: gray;
  padding: 0.5em;
  margin: 0;
  border-right: solid black 1px;
  font-variant: small-caps;
  font-weight: bold;
}

.nav-item {
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

#name {
  font-size: 150%;
  font-weight: 150%;
}

#svgcontainer {
  padding: 10px;
  overflow: hidden;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  border: solid gray 1px;
}

svg text {
  fill: blue;
  cursor: pointer;
  text-decoration: underline;
}

rect.hover {
  cursor: pointer;
}

.relational-tree-title {
  font-size: 150%;
  font-weight: 150%;
}

.field-title {
  font-weight: bold;
  margin-right: 0.5em;
}

.individual-detail, .edit-individual-detail, .account-information, .main {
  max-width: 50em;
  padding: 1em;
}

#name {
  margin: 5px;
}

table.individual-detail-table {
  /* background-color: lightgray; */
  /* border: solid black 1px; */
  margin-top: 0.5em;
}

table.family {
  background-color: lightgray;
  border: solid black 1px;
  margin-top: 0.5em;
}

.field-title {
  margin: 5px;
}

table.family tr td:first-child {
  width: 100px;
}

table.family tr td:last-child {
  text-align: left;
  width: 400px;
}

table.individual-detail-table {
  margin-top: 0.5em;
}

table.individual-detail-table tr td:first-child {
  width: 100px;
}

table.individual-detail-table tr td:last-child {
  text-align: left;
  width: 400px;
}

table.edit-individual-detail-table {
  margin-top: 0.5em;
}

table.edit-individual-detail-table tr td:first-child {
  width: 150px;
  vertical-align: top;
  text-align: left;
}

table.edit-individual-detail-table tr td:last-child {
  text-align: left;
  width: 600px;
  vertical-align: top;
}

.individual-detail button, button.fake-link {
  border: none;
  color: blue;
  background-color: transparent;
  /* padding: 10px 20px; */
  /* text-align: center; */
  margin: 0;
  padding: 0;
  text-decoration: underline;
  display: inline-block;
  font-size: 14px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  cursor: pointer;
}

button.searchResult {
  border: none;
  color: blue;
  background-color: transparent;
  /* padding: 10px 20px; */
  /* text-align: center; */
  margin: 0;
  padding: 0;
  text-decoration: underline;
  display: inline-block;
  font-size: 14px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  cursor: pointer;
}

.relational-tree-legend {
  z-index: 100;
  position: absolute;
  top: 100px;
  left: 30px;
  border: solid black 1px;
  padding: 10px;
  background-color: rgb(252, 78, 3);
}

.relational-tree-legend button {
  padding: 0.5em;
  margin-top: 0.5em;
}

input[type="email"]:invalid {
  box-shadow: red 0px 0px 5px;
}

.error-text {
  color: red;
  font-weight: bold;
}